import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-meis',
  templateUrl: './meis.component.html',
  styleUrls: ['./meis.component.css']
})
export class MeisComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  orders;
  page = 0;
  today;
  dd;
  form: FormGroup;
  loading: boolean = false;
  mm;
  pdfSrc;
  yyyy;
  noMoreData = false;
  selectedBoxIdsForPBE;
  selectedAll;
  isLoading = false;
  noMore: boolean = false;
  constructor(public authService: AuthService, private fb: FormBuilder, private nfs: NotificationService) {
    this.getMeisData()
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      avatar: null
    });
  }

  getMeisData() {
    this.isLoading = true
    this.authService.getMeisData({ page: this.page }).subscribe(data => {
      if (data.success) {
        if (data.data.length > 0) {
          this.orders = data.data;
          this.page = this.page + 1;
          this.noMoreData = false

        }
        else {
          this.noMoreData = true
        }

      }
      else {
        this.nfs.showInfo('Oops!', data.message, 'error')
      }
      this.isLoading = false
    })
  }

  getMeisData1() {

    this.isLoading = true;
    this.page = this.page - 1;
    if (this.page == 0) {
      this.getMeisData();
      this.noMore = false;
    }
    else {
      this.authService.getMeisData({ page: this.page }).subscribe(data => {
        if (data.success) {
          if (data.data.length > 0) {
            this.orders = data.data;
            this.noMoreData = false
          }
          else {
            this.noMoreData = true
          }

        }
        else {
          this.nfs.showInfo('Oops!', data.message, 'error')
        }
        this.isLoading = false
      })
    }
  }

  moreData() {
    this.getMeisData()
    this.noMore = true;
  }

  backData() {
    this.getMeisData1();
  }

  generateMeis(e) {
    var isMail = false;
    var email = '';
    this.authService.downloadAsPdf({ orderIds: this.authService.x, type: 'meis', isMail: isMail, email: email }).subscribe(data => {
      if (data.success) {
        const blob = new Blob([new Uint8Array(data.link.data)], { type: "application/pdf" })
        var objectUrl = URL.createObjectURL(blob);
        if (e == 'view') {
          window.open(objectUrl, '_blank')
        }
        else {
          this.JSONToCSVConvertor(objectUrl, '.pdf')
        }
      }
      else {
        this.nfs.showInfo('Oops!', data.message, 'error')
      }
    })
  }


  JSONToCSVConvertor(url, f) {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    this.today = new Date();
    this.dd = this.today.getDate();
    this.mm = this.today.getMonth() + 1; //January is 0!
    this.yyyy = this.today.getFullYear();

    if (this.dd < 10) {
      this.dd = '0' + this.dd;
      this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
    }

    if (this.mm < 10) {
      this.mm = '0' + this.mm;
      this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
    }


    // console.log(this.today);
    a.download = 'PIKMYBOX_' + this.today + f;
    a.click();
    // window.URL.revokeObjectURL(url);
  }


  excelDownload() {
    var isMail = false;
    var email = '';
    this.authService.downloadMeisExcel({ orderIds: this.authService.x, type: 'meis', isMail: isMail, email: email }).subscribe(data => {
      if (data.success) {

        const blob = new Blob([new Uint8Array(data.link.data)])
        var objectUrl = URL.createObjectURL(blob);
        this.JSONToCSVConvertor(objectUrl, '.xlsx')
      }
      else {
        this.nfs.showInfo('Oops!', data.message, 'info')
      }
    })
  }

  // xlsx


  selectAll() {
    for (var i = 0; i < this.orders.length; i++) {
      this.orders[i].selected = this.selectedAll;
    }
    this.authService.x = this.orders.reduce((ids, thing) => {
      if (thing.selected) {
        ids.push(thing._id);
      }
      return ids;
    }, []);
    this.selectedBoxIdsForPBE = this.authService.x
    // console.log(this.authService.x)
  }

  selectBoxIds(e) {
    if (e.target.checked) {
      var k = this.functiontofindIndexByKeyValue(this.orders, '_id', e.target.value);

      this.orders[k].selected = true
    }
    else {
      var k = this.functiontofindIndexByKeyValue(this.orders, '_id', e.target.value)
      this.orders[k].selected = false
    }
    this.authService.x = this.orders.reduce((ids, thing) => {
      if (thing.selected) {
        ids.push(thing._id);
      }
      return ids;
    }, []);
    this.selectedBoxIdsForPBE = this.authService.x
    // console.log(this.authService.x)
  }
  functiontofindIndexByKeyValue(arraytosearch, key, valuetosearch) {

    for (var i = 0; i < arraytosearch.length; i++) {

      if (arraytosearch[i][key] == valuetosearch) {
        return i;
      }
    }
    return -1;
  }

  onFileChange(event) {
    let reader: any = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.get('avatar').setValue({
          filename: file.name,
          filetype: file.type,
          value: reader.result.split(',')[1]
        })
      };
    }
  }
  clearFile() {
    this.form.get('avatar').setValue(null);
    this.fileInput.nativeElement.value = '';
  }

  onSubmit() {
    const formModel = this.form.value;
    this.loading = true;
    // In a real-world app you'd have a http request / service call here like
    this.authService.meisFormGenerate(formModel).subscribe(data => {
      // alert('done!');
      if (data.success) {
        const blob = new Blob([new Uint8Array(data.link.data)], { type: "application/pdf" })
        var url = URL.createObjectURL(blob);
        // if (p == 'view') {
        //   window.open(url, '_blank')
        // }
        // else {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        this.today = new Date();
        this.dd = this.today.getDate();
        this.mm = this.today.getMonth() + 1; //January is 0!
        this.yyyy = this.today.getFullYear();

        if (this.dd < 10) {
          this.dd = '0' + this.dd;
          this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
        }

        if (this.mm < 10) {
          this.mm = '0' + this.mm;
          this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
        }
        a.download = 'PIKMYBOX' + this.today + ".pdf";
        a.click();
        window.URL.revokeObjectURL(url);
      }
      else {
        this.nfs.showInfo('Sorry!', data.message, 'error');
      }
      // })
      // }
      this.loading = false;
    })
  }
}

