import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DateRangePickerService } from 'src/app/services/date-range-picker.service';
import { NotificationService } from 'src/app/services/notification.service';
declare var grecaptcha: any;

var date = new Date();

var day = date.getDate();
var month = date.getMonth() + 1;
var year = date.getFullYear();

@Component({
  selector: 'app-helpdesk',
  templateUrl: './helpdesk.component.html',
  styleUrls: ['./helpdesk.component.css']
})
export class HelpdeskComponent implements OnInit {
  // @ViewChild(ReCaptchaComponent) captcha: ReCaptchaComponent;
  public toasterService: any;
  mainCaptcha = ''
  bugForm: FormGroup;
  imgArry: any = [];
  currentUrl = "Bug";
  captchaResponse = '';
  captcha = ''
  submitted = false;
  ticketNumber = '';
  feature = false;
  demo = false;
  issue = false;
  name = ''
  email = ''
  mobile = ''
  public config: any;
  public myDatePickerOptions =
    {
      dateFormat: 'dd.mm.yyyy',
      dateRange: false,
      markCurrentDay: true,
    };

  constructor(public authService: AuthService, public activatedRoute: ActivatedRoute, private dp: DateRangePickerService,
    public router: Router, private nfs: NotificationService) {
    // this.toasterService = toasterService;
    // this.config = new ToasterConfig({ positionClass: 'toast-top-center' })

  }

  ngOnInit() {
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    var url = ''
    this.activatedRoute.params.subscribe(params => {
      url = params['id'];
      this.currentUrl = params['id'];
      if (url == 'Issue') {
        this.currentUrl = "Issue";
        this.issue = true;
        this.feature = false;
        this.demo = false;
      }
      else if (url == "DemoRequest") {
        this.currentUrl = "Demo Request";
        this.issue = false;
        this.feature = false;
        this.demo = true;
      }
      else if (url == "FeatureRequest") {

        this.currentUrl = "Feature Request"
        this.issue = false;
        this.feature = true;
        this.demo = false;
      }

      this.bugForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        mobile: new FormControl('', [Validators.required, Validators.maxLength(10)]),
        description: new FormControl(''),
        title: new FormControl(''),
        timeSlot: new FormControl(''),
        date: new FormControl(''),
        url: new FormControl('', [Validators.pattern(reg)]),
        createdBy: new FormControl('')
      })



      this.setPrevValue()
      this.setDate();
    })

    this.Captcha();
  }

  Captcha() {
    this.captcha = '';
    this.mainCaptcha = ''
    // var alpha = new Array('A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',
    // 'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z', 
    //     '0','1','2','3','4','5','6','7','8','9');
    // var i;
    // for (i=0;i<6;i++){
    //     var a = alpha[Math.floor(Math.random() * alpha.length)];
    //     var b = alpha[Math.floor(Math.random() * alpha.length)];
    //     var c = alpha[Math.floor(Math.random() * alpha.length)];
    //     var d = alpha[Math.floor(Math.random() * alpha.length)];
    //     var e = alpha[Math.floor(Math.random() * alpha.length)];
    //     var f = alpha[Math.floor(Math.random() * alpha.length)];
    //     var g = alpha[Math.floor(Math.random() * alpha.length)];
    //                  }
    //     var code = a + ' ' + b + ' ' + ' ' + c + ' ' + d + ' ' + e + ' '+ f + ' ' + g;

    //clear the contents of captcha div first 
    document.getElementById('captcha').innerHTML = "";
    var charsArray =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var lengthOtp = 6;
    var captcha = [];
    for (var i = 0; i < lengthOtp; i++) {
      //below code will not allow Repetition of Characters
      var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
      if (captcha.indexOf(charsArray[index]) == -1)
        captcha.push(charsArray[index]);
      else i--;
    }
    var canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 130;
    canv.height = 50;
    var ctx = canv.getContext("2d");
    ctx.moveTo(0, 0);
    ctx.lineTo(300, 100);
    ctx.stroke();
    ctx.font = "30px Georgia";
    ctx.strokeText(captcha.join(""), 0, 30);
    //storing captcha so that can validate you can save it somewhere else according to your specific requirements
    this.mainCaptcha = captcha.join("");
    document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
    // this.mainCaptcha = code
  }
  ValidCaptcha() {
    event.preventDefault();
    if (this.captcha == this.mainCaptcha) {
      return true;
    } else {
      this.Captcha();
      return false;
    }
  }
  removeSpaces(string) {
    return string.split(' ').join('');
  }

  setPrevValue() {
    if (this.authService.loggedIn() && !this.authService.isSuperAdmin()) {
      this.bugForm.patchValue({
        name: this.authService.getuserProfile().name,
        mobile: this.authService.getuserProfile().mobile,
        email: this.authService.getuserProfile().email,
      })
    }
  }

  setDate(dd: any = ''): void {
    if (dd) {
      this.bugForm.get('date').patchValue(this.dp.GetFormatedDate(dd));
    } else {
      // Set today date using the patchValue function
      let date = new Date();
      this.bugForm.patchValue({
        date: {
          date: {
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear()
          }
        }
      });
    }

  }

  get f(): any { return this.bugForm.controls; }

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {

    this.imgArry = [];
    for (var i = 0; i < inputValue.files.length; i++) {
      this.getBase64(inputValue.files[i]);
    }
  }

  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imgArry.push({ fileName: file.name, fileType: file.type, base64: reader.result });
    };
    reader.onerror = function (error) {
      // console.log('Error: ', error);
    };
  }

  handleCorrectCaptcha(e) {
    this.captchaResponse = e;
  }

  createTicket() {
    if (this.ValidCaptcha()) {
      this.submitted = true;
      if (this.bugForm.invalid) {
        return;
      }
      else {

        if (this.authService.loggedIn()) {
          this.bugForm.value.createdBy = this.authService.getuserProfile().name;
        }
        else {
          this.bugForm.value.createdBy = this.name;

        }

        this.bugForm.value.type = this.currentUrl;
        this.bugForm.value.images = this.imgArry;
        // this.bugForm.value["g-recaptcha-response"]=this.captchaResponse;
        this.authService.createTicket(this.bugForm.value).subscribe(response => {
          if (response.success == true) {
            this.Captcha()
            this.ticketNumber = response.message;
            this.toasterService.pop('success', '', response.message);
            this.bugForm.reset();
            this.imgArry = [];
            (<HTMLInputElement>document.getElementById("images")).value = "";
            // this.captcha.reset();
            setTimeout(() => {
              this.setPrevValue()
            }, 500);
            this.submitted = false;
          }
          else {
            this.toasterService.pop('warning', '', response.message);
            // this.captcha.reset();
          }
        })
      }
    }
    else {
      this.Captcha();
      this.captcha = ''
      this.nfs.showInfo('Oops!', 'Invalid captcha, try again', 'error')
    }


  }

  getValue(e) {
    if (e.target.value == 'Demo Request') {
      this.issue = false;
      this.feature = false;
      this.demo = true;
    }
    else if (e.target.value == "Feature Request") {
      this.issue = false;
      this.feature = true;
      this.demo = false;
    }
    else if (e.target.value == "Issue") {
      this.issue = true;
      this.feature = false;
      this.demo = false;
    }

  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }


}

