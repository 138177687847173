import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

declare function unescape(p: any);

@Component({
  selector: 'app-trackmyparcel',
  templateUrl: './trackmyparcel.component.html',
  styleUrls: ['./trackmyparcel.component.css']
})
export class TrackmyparcelComponent implements OnInit {
  // @ViewChild(ReCaptchaComponent) captcha: ReCaptchaComponent;
  trackingId: any = "";
  captchaResponse = '';
  capcha: any = "";
  trackData: any;
  trackingData;
  mainCaptcha = ''
  captcha = ''
  serviceProvider = "Indiapost"
  loading = false;

  constructor(public service: AuthService, public activatedRoute: ActivatedRoute,
    private nfs: NotificationService) { }

  ngOnInit() {

    this.activatedRoute.params.subscribe((params: any) => {
      this.trackingId = params.id.split('-')[0];

      if (this.trackingId == 'track') {
        this.trackingId = "";
      }
      else {
        if (params.id.split('-')[1]) {
          if (params.id.split('-')[1] == 'dhl') {
            this.serviceProvider = 'DHLeCom'
          }
        }
      }
    })
    // this.Captcha()
  }

  Captcha() {
    this.captcha = '';
    this.mainCaptcha = ''
    document.getElementById('captcha').innerHTML = "";
    var charsArray =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var lengthOtp = 6;
    var captcha = [];
    for (var i = 0; i < lengthOtp; i++) {
      //below code will not allow Repetition of Characters
      var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
      if (captcha.indexOf(charsArray[index]) == -1)
        captcha.push(charsArray[index]);
      else i--;
    }
    var canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 130;
    canv.height = 50;
    var ctx = canv.getContext("2d");
    ctx.moveTo(0, 0);
    ctx.lineTo(300, 100);
    ctx.stroke();
    ctx.font = "30px Georgia";
    ctx.strokeText(captcha.join(""), 0, 30);
    //storing captcha so that can validate you can save it somewhere else according to your specific requirements
    this.mainCaptcha = captcha.join("");
    document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
  }

  ValidCaptcha() {
    event.preventDefault();
    if (this.captcha == this.mainCaptcha) {
      return true;
    } else {
      this.Captcha();
      return false;
    }
  }
  removeSpaces(string) {
    return string.split(' ').join('');
  }

  track() {

    // if(this.ValidCaptcha()){
    this.loading = true;
    this.trackData = null;
    this.trackingData = null
    this.service.trackParcel({ trackingNo: this.trackingId.trim(), ["g-recaptcha-response"]: this.captchaResponse, serviceProvider: this.serviceProvider }).subscribe(response => {
      this.loading = false;
      let myWindow: any = window;
      myWindow.grecaptcha.reset();
      if (response.success == true) {
        this.trackData = response.data;
        this.captchaResponse = ''
        if (this.serviceProvider == 'Indiapost') {
          if (unescape(response.data) == null || unescape(response.data) == 'null' || unescape(response.data) == '') {
            this.trackData = '<h5 class="text-center">No tracking info. available for tracking Id <b>' + this.trackingId + '</b></h5>'
            // this.Captcha()
          }
          else {
            this.trackData = '<table class="table table-striped table-bordered trackTbl"><thead><tr><th class="text-center">Date & Time</th><th class="text-center">Country</th><th class="text-center">Location</th><th class="text-center">Event</th><th class="text-center">Mail Category</th><th class="text-center">Next Office</th><th class="text-center" colspan="2">Extra Information</th><tbody>' + unescape(response.data) + '</tbody></table>';
            // this.Captcha()
          }
        }
        else {
          this.trackingData = response.data.trackItemResponse.bd.shipmentItems[0]
        }
      }
      else {
        this.nfs.showInfo('Oops!', response.message, 'error')
        // this.Captcha()
      }
    })
    // }
    // else{
    //   this.Captcha();
    //   this.captcha=''
    //   swal('Oops!','Invalid captcha, try again','error')
    // }
  }

  handleCorrectCaptcha(e) {
    // console.log(e)
    this.captchaResponse = e;
  }

  reset() {
    this.captchaResponse = ''
    this.trackData = "";
    let myWindow: any = window;
    myWindow.grecaptcha.reset();
    // this.Captcha()
  }

}

