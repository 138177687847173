import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-gstin',
  templateUrl: './gstin.component.html',
  styleUrls: ['./gstin.component.css']
})
export class GstinComponent implements OnInit {
  isSubValid = false
  isPremium = false
  year: any = [];
  month: any = [];
  subscription = null
  mnth: any;
  yrr: any;
  today;
  dd;
  mm;
  yyyy;
  loading: boolean = false;
  serviceProvider = 'indiapost';
  status = '';
  constructor(public authService: AuthService, private nfs: NotificationService) {
    this.checkforsubscription()
  }

  ngOnInit() {
    this.month = [
      { id: "01", name: "January" },
      { id: "02", name: "Febuary" },
      { id: "03", name: "March" },
      { id: "04", name: "April" },
      { id: "05", name: "May" },
      { id: "06", name: "June" },
      { id: "07", name: "July" },
      { id: "08", name: "August" },
      { id: "09", name: "September" },
      { id: "10", name: "October" },
      { id: "11", name: "November" },
      { id: "12", name: "December" }
    ]

    this.year = [{ name: "2018" }, { name: "2019" }, { name: "2020" },
    { name: "2021" }, { name: "2022" }, { name: "2023" }, { name: "2024" },
    { name: "2025" }, { name: "2026" }, { name: "2027" }, { name: "2028" },
    { name: "2029" }, { name: "2030" }]

  }

  checkforsubscription() {
    // this.loader = false;
    this.isSubValid = false
    this.authService.checkforsubscription().subscribe(data => {
      if (data.success) {
        this.subscription = data.data
        if (!this.subscription.planId.includes('MLT')) {
          this.isPremium = true
        }
        else {
          this.isPremium = false
        }
        this.isSubValid = true
        // console.log(this.isPremium)
      }
      else {
        this.isSubValid = false
        this.nfs.showInfo('Oops!', data.message, 'info')
      }
    })
  }

  getMonth(val) {
    this.mnth = val.target.value;
  }

  getYear(val) {
    this.yrr = val.target.value;
  }

  selectedNote = "0"
  noteChange(val) {
    this.selectedNote = val.target.value;
  }


  downloadGstin() {
    if (this.yrr == undefined) {
      if (this.mnth == undefined) {
        this.nfs.showInfo('Oops !!', 'Please select a month', 'info');
      }
      else {
        this.nfs.showInfo('Oops !!', 'Please select a year', 'info');
      }
    }
    else {
      var obj = {
        month: Number(this.mnth),
        year: Number(this.yrr), ieCode: "",
        selectedNote: this.selectedNote,
        serviceProvider: this.serviceProvider,
        status: this.status,
      };

      this.loading = true;

      this.authService.downloadGstin(obj).subscribe(data => {
        this.loading = false;
        if (data.success == true) {
          const blob = new Blob([new Uint8Array(data.link.data)])
          var objectUrl = URL.createObjectURL(blob);
          this.JSONToCSVConvertor(objectUrl, '.xlsx')
          this.selectedNote = "0"
        }
        else {
          this.nfs.showInfo('Oops !!', data.message, 'error');
        }
      })
    }
  }


  JSONToCSVConvertor(url, f) {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    this.today = new Date();
    this.dd = this.today.getDate();
    this.mm = this.today.getMonth() + 1; //January is 0!
    this.yyyy = this.today.getFullYear();

    if (this.dd < 10) {
      this.dd = '0' + this.dd;
      this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
    }

    if (this.mm < 10) {
      this.mm = '0' + this.mm;
      this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
    }

    var monthName = this.month.filter(e => e.id == this.mnth)
    // console.log(this.today);
    a.download = 'PIKMYBOX_GSTR_' + monthName[0].name.toUpperCase() + f;
    a.click();
    // window.URL.revokeObjectURL(url);
  }

}

