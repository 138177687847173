import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-adminlogin',
  templateUrl: './adminlogin.component.html',
  styleUrls: ['./adminlogin.component.css']
})
export class AdminloginComponent implements OnInit {
  selectedUserType = '1';
  greeting;
  showUser = true;
  showPass = true;
  constructor(public authService: AuthService, public router: Router, private nfs: NotificationService) { }

  ngOnInit() {
    var d = new Date();
    var time = d.getHours();

    if (time < 12) {
      this.greeting = "Good morning!";
    }
    if (time > 12 && time < 16) {
      this.greeting = "Good afternoon!"
    }
    if (time > 16) {
      this.greeting = "Good Evening!";
    }
    if (time == 12) {
      this.greeting = "Good Noon!";
    }
  }

  login() {
    var iecode = '';
    var pwd = (<HTMLInputElement>document.getElementById('userpassword')).value;
    if ((pwd.length >= 5)) {
      iecode = (<HTMLInputElement>document.getElementById('uname')).value;
      var obj = {
        userType: 10,
        password: pwd,
        userName: iecode
      }
      this.authService.login(obj).subscribe(data => {
        if (data.success) {
          this.authService.d2Login(environment.d2CrdForAuth).subscribe((res: any) => {
            // console.log("d2 login >>> ", res);
            if (res.success) {
              localStorage.setItem("d2Auth", res.token);
            } else {
              console.log("d2 auth fail >> ", res);
            }
          });
          this.authService.storeUserData(data);
          this.authService.establishSocketConnection();
          this.router.navigate(['/home']);
        }
        else {
          this.nfs.showInfo('Oops!!!', data.message, 'error')
        }
      })
    }
    else {
      this.nfs.showInfo('Oops!!!', 'Please provide username & password', 'error')
    }
  }

  changeUserType(val) {
    this.selectedUserType = val
  }

  forget() {
    this.showUser = true;
    this.showPass = false;
  }

  resetPass() {
    var obj = {
      userType: "10",
      userName: (<HTMLInputElement>document.getElementById('uname')).value
    }
    this.authService.resetPassword(obj).subscribe(result => {
      if (result.success) {
        this.nfs.showInfo('Success!!!', result.message, 'success')
      }
      else {
        this.nfs.showInfo('Error!!!', result.message, 'error')
      }
    })
  }

}
