import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
declare var $: any;

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {
  pdfSrc;
  subscription: any;
  isUpgrade = false
  isMonthly = true;
  currentPlan: any;
  isChangePlan = false;
  ieCode = ''
  mobile = '';
  email = '';
  refferals = [];
  userType = '1';
  planAmount = 0
  refBy = 'Direct'
  constructor(public authService: AuthService, public router: Router, private nfs: NotificationService) {
    this.authService.hideNav()
    // this.cheForSub()

  }
  ngOnInit() {
    this.getreferrals();
  }

  apiPlan() {
    document.getElementById('contactForm').style.display = 'block';
    document.getElementById('launcher').style.display = 'none';
    document.getElementById('contactForm').classList.add('animate');
  }

  checkforsubscription(subType) {
    if (subType == 'basic') {
      if (this.isMonthly) {
        this.planAmount = 349  // old price 149
      }
      else {
        this.planAmount = 3499  // old peice 1499
      }
    }
    else {
      if (this.isMonthly) {
        this.planAmount = 599    // old price 199
      }
      else {
        this.planAmount = 5999  // old price 1999
      }
    }
    if (this.authService.isExporterAdmin()) {
      this.authService.checkforsubscription().subscribe(res => {
        if (res.success) {
          this.subscription = res.data
          // if(this.subscription.isExpired){
          var diff = this.planAmount - res.data.amount;
          this.currentPlan = res.data;
          // if(currentPlan.currentPlan.includes('MTL') && this.isMonthly){

          // }
          if (this.currentPlan.subType == 'custom') {
            this.nfs.showInfo('Sorry!', 'You are on the custom plan, if you wish to change plan kindly contact us on support@pikmybox.com', 'info')
          }
          else {
            if (diff > 0) {
              this.isChangePlan = true
              this.isUpgrade = true
              // $('#nextMonthbtn').css('display','block')
              $('#diffText').text('Are you sure you want to upgrade your plan? You will be charged the difference ₹' + diff + ' + GST. ')
              $('#upgradePlan').modal();
              this.isChangePlan = true
            }
            else if (diff == 0) {
              this.isChangePlan = false;
              this.isUpgrade = false
              this.nfs.showInfo('Oops!', 'You have already subscribed to this Plan.', 'error')
            }
            else {
              this.isChangePlan = true;
              this.isUpgrade = false
              // $('#nextMonthbtn').css('display','none')
              $('#diffText').text('Are you sure you want to downgrade your plan? You can continue to use your current plan till end of the billing cycle.')
              $('#upgradePlan').modal()
              // $('#nextMonthbtn').css('display','none')
            }
          }

          // }
        }
        else {
          this.subscribePlan()
        }
      })
    }
    else {
      this.nfs.showInfo('Sorry!', 'This feature is in development phase.', 'info')
    }
  }


  cheForSub() {
    this.authService.checkforsubscription().subscribe(res => {
      if (res.success) {
        this.subscription = res.data
        // if(this.subscription.isExpired){
        var diff = this.planAmount - res.data.amount;
        this.currentPlan = res.currentPlan;
      }
      else {
        this.subscribePlan()
      }
    })
  }

  getreferrals() {
    this.authService.getreferrals().subscribe(response => {
      if (response.success == true) {
        this.refferals = response.data;
        console.log(this.refferals);
        var d = this.refferals.filter(e => e.id == this.refBy.toLowerCase().trim());
        if (d.length == 0 && this.refBy != 'direct') {
          this.refBy = 'direct'
        }
        else {
          this.refBy = d[0].id;
        }
      }
      else {
        this.nfs.showInfo('Oops!', response.message, 'error');
      }
    })
  }

  register() {
    var obj = {
      mobile: this.mobile,
      email: this.email,
      refBy: this.refBy,
      userType: this.userType
    }
    localStorage.setItem('userData', JSON.stringify(obj))
    this.router.navigate(['/register/' + this.refBy])
  }


  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  changeMthlToAnnual(e) {
    this.isMonthly = !e.target.checked

  }


  subscribePlan() {
    this.nfs.showConfirmation(
      'Are you sure want to subscribe to this Plan?',
      '<h6>Please note, you will be charged ₹'
      + this.planAmount
      + ' + GST</h6>  <p style="color:#000; font-size: 10px;margin-top: 15px;">"By using our services, you are agreeing to our <a style="text-decoration: underline;    color: #ac5cf9;" href="/termsofuse">Terms of Use</a>  and    <a href="/privacypolicy"><span style="text-decoration: underline;    color: #ac5cf9;">Privacy Policy"</span></a></p>',
      'question',
      'Yes', true
    ).then((result) => {
      if (result.value) {
        this.authService.subscribePlan({ planAmount: this.planAmount, isChangePlan: false, isUpgrade: this.isUpgrade, walletType: "export" }).subscribe(res => {
          if (res.success) {
            this.nfs.showInfo('Success', res.message, 'success')
            // this.getusertransaction();
            // this.getuserWallet()
            // console.log(res)
          }
          else {
            if (res.message == 'You have already subscribed to this Plan.') {
              var diff = this.planAmount - res.data.amount
              if (diff > 0) {
                $('#diffText').text('Are you sure you want to upgrade your plan? You will be charged the difference ₹' + diff + ' + GST. ')
                $('#upgradePlan').modal()
              }
              else if (diff == 0) {
                this.nfs.showInfo('Oops!', res.message, 'error')
              }
              else {
                $('#diffText').text('Are you sure you want to downgrade your plan? You can continue to use your current plan till end of the billing cycle.')
                $('#upgradePlan').modal()
              }
            }
            else {
              this.nfs.showInfo('Oops!', res.message, 'error')
            }
            // console.log(res)
          }
        })
      }
    })
  }
  upgradePlan(v) {
    this.authService.subscribePlan({ planAmount: this.planAmount, isChangePlan: true, isUpgrade: this.isUpgrade, userRes: v, walletType: "export" }).subscribe(res => {
      if (res.success) {
        this.nfs.showInfo('Success', res.message, 'success')
      }
      else {
        this.nfs.showInfo('Oops!', res.message, 'error')
      }
    })
  }

}

