import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-postalbill',
  templateUrl: './postalbill.component.html',
  styleUrls: ['./postalbill.component.css']
})
export class PostalbillComponent implements OnInit {
  today;
  dd;
  mm;
  yyyy;
  constructor(public router: Router, public authService: AuthService, private nfs: NotificationService) { }

  ngOnInit() {
  }

  print(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
  }
  myFunction() {
    this.router.navigate(['/orders'])
  }

  sendDownloadRequest() {
    this.authService.pboeprintRequest().subscribe(data => {
      if (data.success) {
        const blob = new Blob([new Uint8Array(data.link.data)])
        // console.log(blob)
        var objectUrl = URL.createObjectURL(blob);
        this.JSONToCSVConvertor(objectUrl)
        this.nfs.showInfo('Downloading', 'Pdf file is downloading', 'success')
      }
      else {
        this.nfs.showInfo('', data.message, 'info')
      }


    })
  }


  JSONToCSVConvertor(url) {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    this.today = new Date();
    this.dd = this.today.getDate();
    this.mm = this.today.getMonth() + 1; //January is 0!
    this.yyyy = this.today.getFullYear();

    if (this.dd < 10) {
      this.dd = '0' + this.dd;
      this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
    }

    if (this.mm < 10) {
      this.mm = '0' + this.mm;
      this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
    }


    // console.log(this.today);
    a.download = 'PIKMYBOX_' + this.today + ".pdf";
    a.click();
    window.URL.revokeObjectURL(url);
  }

}
