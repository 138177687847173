import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SocketService } from 'src/app/services/socket.service';
declare var $: any;
declare function unescape(p: any);

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  hideNavbar = true;
  route: string;
  isAdModal = false
  trackingData = ''
  trackingId = ''
  isTracking = false
  constructor(private sanitizer: DomSanitizer, public authService: AuthService,
    public router: Router, private socketService: SocketService, private notification: NotificationService) {

  }

  ngOnInit() {

  }

  onLogoutClick() {

    this.notification.showConfirmation('Are you sure?', "You want to logout!", 'warning', "Yes Logout").then((result) => {
      if (result.value) {
        this.authService.logoutFormServer().subscribe(res => {
          localStorage.removeItem('token');
          localStorage.removeItem('authToken');
          this.router.navigate(['/']);
        });

      }
    })
  }

  trackShipment() {
    this.trackingId = this.trackingId.replace(/\s/g, '');
    this.trackingId = this.trackingId.toUpperCase();
    if (this.trackingId.length > 12 && this.trackingId.length < 15) {
      this.isTracking = true;
      this.trackingData = '<h5 class="text-center"><i class="fa fa-spinner fa-spin text-primary" style="font-size:30px" ></i>&nbsp; Please wait while we get tracking information for ' + this.trackingId + '</h5>'
      this.authService.trackshipment({ trackingId: this.trackingId }).subscribe(d => {
        if (d.success) {
          if (unescape(d.data) == null || unescape(d.data) == 'null' || unescape(d.data) == '') {
            this.trackingData = '<h5 class="text-center">No tracking info. available for tracking Id <b>' + this.trackingId + '</b></h5>'
          }
          else {
            this.trackingData = unescape(d.data);
            setTimeout(() => {
              if (!$('#trackShipment table').hasClass('table'))
                $('#trackShipment table').addClass('table table-bordered text-center table-striped')
            }, 500);
          }
          // console.log(this.trackingData)
        }
        else {
          this.notification.showInfo('Error', d.message, 'error');
        }

      })
    }
    else {
      this.notification.showInfo('Error', 'Invalid Tracking No.', 'error')
    }
  }
  trackMore() {
    this.isTracking = false;
    this.trackingId = ''
    this.trackingData = ''
  }

  openModal() {
    this.isAdModal = true
    setTimeout(() => {

      // var i = <HTMLIFrameElement>document.getElementById('adIframe')
      // i.contentDocument.location.reload(true);

      $("#adIframe").load(function () {
        var i = <HTMLIFrameElement>document.getElementById('adIframe')
        i.contentDocument.location.reload();
      });


      $('#adCodeReg').modal()

    }, 500);
  }

  closeModal() {
    $('#adCodeReg').modal('hide')
    this.isAdModal = false
  }

  payForAdCodeReg() {
    this.notification.showConfirmation(
      'Are you sure?',
      "Do you want to make payment of Rs. 2000 + 18% GST for AD Code Registration, once paid it can't be refunded!",
      'warning',
      'Confirm Payment',
    ).then((result) => {
      if (result.value) {
        this.authService.getReq('lookup/payforadCodeReg').subscribe((res) => {
          if (res.success) {
            this.notification.showInfo('Success!', res.message, 'success');
            var i = <HTMLIFrameElement>document.getElementById('adIframe')
            i.src = 'https://www.pikmybox.com/api/v1/adCoderegiframe'
          }
          else {
            this.notification.showInfo('Oops!', res.message, 'error');
          }
        })
      }
    })
  }

  redirectToDomestic() {
    let redirectToken = localStorage.getItem("authToken");
    window.open(`https://betad.pikmybox.com?token=${redirectToken}`, "_self");
  }
}
