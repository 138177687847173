import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  selectedUserType = '1';
  isForgotPassword = false;
  isDomesticLogin = false;

  constructor(public authService: AuthService, public router: Router,
    private nfs: NotificationService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      console.log("login params >>> ", params);
      if (params && (params['logout'] || params['logout'] == 'successIntentionally')) {

        console.log("Need to logout")
        localStorage.removeItem("token"); // bcoz token already expired at domestic end
        localStorage.removeItem("authToken");
        localStorage.clear()
        this.router.navigate(['./login']);

      }
      // else if (localStorage.getItem("token")) {
      //   this.router.navigate(['./home']);
      // }
    })

  }

  login() {
    var iecode = '';
    var mobile = '';
    var obj;

    var pwd = (<HTMLInputElement>document.getElementById('userpassword')).value;
    if ((pwd.length >= 5)) {
      if (this.selectedUserType == '1') {
        iecode = (<HTMLInputElement>document.getElementById('iecode')).value;
        obj = {
          userType: this.selectedUserType,
          password: pwd,
          userName: iecode,
          isDomesticLogin: this.isDomesticLogin
        }
      }
      else if (this.selectedUserType == '2') {
        iecode = (<HTMLInputElement>document.getElementById('chaLicenseNo')).value;
        obj = {
          userType: this.selectedUserType,
          password: pwd,
          userName: iecode,
          isDomesticLogin: this.isDomesticLogin
        }
      }
      else if (this.selectedUserType == '3') {
        mobile = (<HTMLInputElement>document.getElementById('mobile')).value;

        obj = {
          userType: this.selectedUserType,
          password: pwd,
          userName: mobile,
          isDomesticLogin: this.isDomesticLogin
        }
      }
      else {
        iecode = (<HTMLInputElement>document.getElementById('email')).value;
      }

      this.authService.login(obj).subscribe(data => {
        let { isActive, userDetails } = data;
      
        if (data.success && !userDetails) {
          this.authService.storeUserData(data);
          let { redirectToken } = data;
          let userData = this.authService.decodeUser();
         

          if ((userData.isExporter && userData.isActive) || ((userData.userType != 21 || userData.userType != 1) && userData.isActive)) {
            /** get AUTH token from localstorage */
            // let token = localStorage.getItem("authToken");

            this.authService.establishSocketConnection();
            this.router.navigate(['/home']);
          }
          
          else if ((userData.userType == 21 || userData.userType == 1) && userData.isDomestic && userData.isDomesticActive) {
            window.open(`${environment.domesticUrl}?token=${redirectToken}`, "_self");
            // } else {
            //   swal('Oops!!!', "Your Domestic account is not activated.", 'error');
            // }
          }
          else {
            this.nfs.showInfo('Oops!!!', "Your account is not activated.", 'error');
          }
        }
        else if (isActive == false && userDetails) {
        
          console.log("redirect to register step 2");
          this.router.navigate(['/register', userDetails.userId]);
        }
        else {
          this.nfs.showInfo('Oops!!!', data.message, 'error');
        }
      })
    }
    else {
      this.nfs.showInfo('Oops!!!', 'Please provide IEC with Password', 'error');
    }
  }

  changeUserType(val) {
    this.selectedUserType = val
  }

  reset(val) {
    this.isForgotPassword = val
  }

  forgot() {
    var iecode = '';
    if (this.selectedUserType == '1') {
      iecode = (<HTMLInputElement>document.getElementById('iecode')).value;
    }
    else if (this.selectedUserType == '2') {
      iecode = (<HTMLInputElement>document.getElementById('chaLicenseNo')).value;
    }
    else {
      iecode = (<HTMLInputElement>document.getElementById('email')).value;
    }
    var obj = {
      userType: this.selectedUserType,
      userName: iecode
    }

    if (iecode) {
      this.authService.resetPassword(obj).subscribe(result => {
        if (result.success) {
          // swal('Success!!!', result.message, 'success')
          this.authService.showAlert(result.message)
        }
        else {
          // swal('Error!!!', result.message, 'error')
          this.authService.showAlert(result.message)
        }
      })
    } else {
      this.authService.showAlert("Invalid username")
    }
  }

  gotoDomestic() {
    this.isDomesticLogin = !this.isDomesticLogin;
    this.selectedUserType = '1';
    // console.log("  this.isDomesticLogin >> ", this.isDomesticLogin);
  }

}

