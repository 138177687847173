import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PagerService } from 'src/app/services/pager.service';

declare var $: any;

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {
  loading = false;
  dhlBalance = 'N/A'
  invoiceNo = ''
  dd: any;
  isPreview = false;
  today: any;
  pager: any = {};
  pagedItems: any[];
  mm: any;
  invoiceFileName: any = ''
  yyyy: any
  invoiceBase64: any
  dhlInv = [];
  filter = ''
  invoiceCount = 0
  invoices = []
  constructor(public authService: AuthService, public router: Router,
    public pagerService: PagerService, private nfs: NotificationService) { }

  ngOnInit() {
    this.setPage(1);
  }


  setPage(page: number) {
    this.router.navigate(['/billing/' + page])
    // console.log(this.allCsbCount)
    this.loading = true
    // this.filter=filter
    this.authService.getalldhlBillscount({ filter: this.filter }).subscribe(res => {
      if (res.success) {
        this.invoiceCount = res.count;
        // this.setPage(1);
        this.pager = this.pagerService.getPager(this.invoiceCount, page, 20);
        // get current page of items
        this.pagedItems = this.invoices.slice(this.pager.startIndex, this.pager.endIndex + 1);
        this.getDhlInvoice(page);
        if (this.filter != 'vat') {
          this.getdhlBillSummary();
        }
      }
      else {
        this.nfs.showInfo('', res.message, 'info')
      }
      this.loading = false
    })
  }

  getalldhlBillscount() {

  }

  updateBills(type) {
    this.loading = true
    this.authService.updateDhlInvoices({ type: type, invoiceNo: this.invoiceNo }).subscribe(res => {
      if (res.success) {
        this.invoiceNo = ""
        this.setPage(1);
        this.nfs.showInfo('', res.message, 'success')
      }
      else {
        this.nfs.showInfo('', res.message, 'error')
      }
      this.loading = false
    })
  }


  readFile($event, type): void {
    this.readThisFile($event.target, type);
  }

  readThisFile(inputValue: any, type): void {
    for (var i = 0; i < inputValue.files.length; i++) {
      this.getInvoiceBase64(inputValue.files[i], type, this);
    }
  }

  getInvoiceBase64(file, type, _this) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // this.docs.push({ fileName: file.name, fileType: file.type, base64: reader.result });
      console.log(reader.result)
      this.invoiceBase64 = reader.result
      this.invoiceFileName = file.name
    };
    reader.onerror = function (error) {
      _this.nfs.showInfo('Oops!', 'Error while reading file, try again', 'error')
    };
  }

  resetAll() {
    this.isPreview = false;
    this.invoiceBase64 = ""
    this.invoiceFileName = ""
    $('#dhlInvoice').val('')
  }
  public restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    // return !!/[\d\s]/.test(input);
    return !!/[\d\.\s]/.test(input);
  }

  genVatInvoice(inv, type) {
    let vat = inv != '' ? $('#' + inv.shipmentID).val() : '';
    if ((vat && vat > 0) || type == 'paid') {
      this.loading = true
      let bd = type == 'generate' ? { invoiceNo: inv.invoiceNumber, boxId: inv.shipmentID, vat: vat, type: type } : { invoiceNo: this.invoiceNo, type: type }
      this.authService.postReq('excel/updateVat', bd).subscribe((res) => {
        if (res.success) {
          this.loading = false;
          this.invoiceNo = ''
          this.setPage(1)
        }
        else {
          this.loading = false
          this.authService.showAlert(res.message)
        }
      })
    }
    else {
      this.authService.showAlert('Enter VAT value greater then 0.')
    }
  }

  uploadDhlInvoice(type) {
    this.loading = true;
    let ob = {};
    if (type == 'preview') {
      this.isPreview = true
      ob = { file: this.invoiceBase64, type: type }
      this.authService.processdhlinvoice(ob).subscribe(res => {
        console.log(res)
        if (res.success) {
          this.dhlInv = res.data
          this.loading = false
          // this.isPreview= false
        }
        else {
          // this.dhlInv  = []
          this.loading = false;
          this.nfs.showInfo('Oops!', res.message, 'error')
        }
      })
    }
    else if (type != 'download') {
      this.isPreview = true
      ob = { type: type, invoiceData: this.dhlInv }
      this.authService.processbillfile(ob).subscribe(res => {
        console.log(res)
        if (res.success) {
          this.dhlInv = []
          this.loading = false;
          this.isPreview = false
          this.setPage(1);
          this.resetAll()
          this.nfs.showInfo('Success!', res.message, 'success')
        }
        else {
          this.loading = false
          this.nfs.showInfo('Oops!', res.message, 'error')
        }
      })
    }
    else {
      ob = { type: type, invoiceData: this.dhlInv }
      this.authService.gendhlbillfile(ob).subscribe(res => {
        // console.log(res)
        if (res.success) {
          this.nfs.showInfo('Success!', res.message, 'success');
          const blob = new Blob([new Uint8Array(res.link.data)])
          var objectUrl = URL.createObjectURL(blob);
          this.JSONToCSVConvertor(objectUrl, '.xlsx', type)

          this.loading = false
        }
        else {
          this.dhlInv = []
          this.loading = false
        }
      })
    }

  }


  getdhlBillSummary() {
    this.authService.getdhlBillSummary({}).subscribe(res => {
      if (res.success) {
        this.dhlBalance = res.data.toFixed(2)
      }
      else {
        this.dhlBalance = "N/A"
      }
    })
  }

  genTaxtInv(id) {
    this.loading = true
    this.authService.getTaxInvForSubs(id.replace(/\//g, '-')).subscribe(response => {
      if (response.success == true) {
        if (response.isPdf) {
          const blob = new Blob([new Uint8Array(response.link.data)], { type: "application/pdf" })
          var url = URL.createObjectURL(blob);
          window.open(url, '_blank')
        }
        else {
          var myWindow = window.open("", "receipt", "width=800,height=800");
          myWindow.document.write(response.data);
        }
      }
      else {
        this.nfs.showInfo('error', response.message, 'error');
      }
      this.loading = false
    })
  }


  getDhlInvoice(page) {
    this.loading = true;
    let ob = {};
    ob = { file: this.invoiceBase64, type: 'view', page: page, filter: this.filter }
    this.authService.processdhlinvoice(ob).subscribe(res => {
      console.log(res)
      if (res.success) {
        this.invoices = res.data;
        this.isPreview = false;
        this.dhlInv = [];
        this.loading = false;
        //  this.setPage(1)
      }
      else {
        this.isPreview = false;
        this.invoices = []
        this.loading = false;
        this.nfs.showInfo('Oops!', res.message, 'error')
      }
    })
  }



  JSONToCSVConvertor(url, f, type) {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    this.today = new Date();
    this.dd = this.today.getDate();
    this.mm = this.today.getMonth() + 1; //January is 0!
    this.yyyy = this.today.getFullYear();

    if (this.dd < 10) {
      this.dd = '0' + this.dd;
      this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
    }

    if (this.mm < 10) {
      this.mm = '0' + this.mm;
      this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
    }


    // console.log(this.today);
    a.download = 'PIKMYBOX_' + type.toUpperCase() + this.today + f;
    a.click();
    // window.URL.revokeObjectURL(url);
  }

}

