<div class="container-fluid p-0">
    <div class="clr1 text-white" style="height:22rem;width:100%; padding: 0">
        <h3 class=" text-center" style="padding-top: 151px;width: 100%;height: 2rem;">Pricing that makes sense</h3>
        <h6 class=" text-center" style="padding-top: 40px;width: 100%;height: 2rem;">Pay only for the features you use.
        </h6>
    </div>
</div>
<div class="container-fluid p-0">
    <h4 class="clr2">Subscription Plans</h4>


    <div class="row-screen">

        <div class="container-fluid row mt-2">
            <div class="col-sm m-4">
                <h5 class="ml-4">
                    <img alt="" src="assets/images/data.png" class="mr-3"> Data Upload Feature
                </h5>
                <div class="mt-3 data-entry-section">
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Data entry form
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Excel Bulk Upload (General, Amazon and Ebay)
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Autofill of consignee and item details
                    </div>
                </div>
            </div>
            <div class="col-sm mt-5">
                <div class="row">
                    <div class="col-sm m-2 text-center">
                        <div class="card card1 text-center">
                            <p class="free-text">Free</p>
                            <i class="fa fa-check" style="color:#48a81d"></i>
                            <br>
                            <i class="fa fa-circle text-grey"></i>
                            <br>
                            <i class="fa fa-circle text-grey" style="margin-bottom: 20px;"></i>
                        </div>
                    </div>
                    <div class="col-sm m-2">
                        <div class="card card2 text-center">
                            <p class="basic-text">Basic</p>
                            <i class="fa fa-check" style="color:#48a81d"></i>
                            <br>
                            <i class="fa fa-check" style="color:#48a81d"></i>
                            <br>
                            <i class="fa fa-circle text-grey" style="margin-bottom: 20px;"></i>
                        </div>
                    </div>
                    <div class="col-sm m-2">
                        <div class="card card3 text-center">
                            <p class="premium-text">Premium</p>
                            <i class="fa fa-check" style="color:#48a81d"></i>
                            <br>
                            <i class="fa fa-check" style="color:#48a81d"></i>
                            <br>
                            <i class="fa fa-check" style="margin-bottom: 20px;color:#48a81d"></i>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <div class="container-fluid row">
            <div class="col-sm m-4">
                <h5 class="ml-4">
                    <img alt="" src="assets/images/doc.png" class="mr-3 font-weight-bold"> Document Download Feature
                </h5>
                <div class="mt-3 data-entry-section">
                    <div style="margin-top: 1rem;font-size: 13px;">
                        PBE, RBI, EDF, CN22/23, Shipping Labels, Packinglist
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Tax/Export Invoice
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        MEIS
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        GSTR Data Download
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Analytics
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        e-BRC
                    </div>
                </div>
            </div>
            <div class="col-sm mt-2">
                <div class="row ">
                    <div class="col-sm  m-2 text-center">
                        <div class="card card1 text-center">
                            <p class="free-text">Free</p>
                            <i class="fa fa-check" style="color:#48a81d"></i>
                            <br>
                            <i class="fa fa-circle text-grey"></i>
                            <br>
                            <i class="fa fa-circle text-grey"></i>
                            <br>
                            <i class="fa fa-circle text-grey"></i>
                            <br>
                            <i class="fa fa-circle text-grey"></i>
                            <br>
                            <i class="fa fa-circle text-grey" style="margin-bottom: 20px;"></i>
                        </div>
                    </div>
                    <div class="col-sm m-2">
                        <div class="card card2 text-center">
                            <p class="basic-text">Basic</p>
                            <i class="fa fa-check" style="color:#48a81d"></i>
                            <br>
                            <i class="fa fa-circle text-grey"></i>
                            <br>
                            <i class="fa fa-circle text-grey"></i>
                            <br>
                            <i class="fa fa-circle text-grey"></i>
                            <br>
                            <i class="fa fa-circle text-grey"></i>
                            <br>
                            <i class="fa fa-circle text-grey" style="margin-bottom: 20px;"></i>
                        </div>
                    </div>
                    <div class="col-sm m-2">
                        <div class="card card3 text-center">
                            <p class="premium-text">Premium</p>
                            <i class="fa fa-check" style="color:#48a81d"></i>
                            <br>
                            <i class="fa fa-check" style="color:#48a81d"></i>
                            <br>
                            <i class="fa fa-check" style="color:#48a81d"></i>
                            <br>
                            <i class="fa fa-check" style="color:#48a81d"></i>
                            <br>
                            <i class="fa fa-check" style="color:#48a81d"></i>
                            <br>
                            <i class="fa fa-check" style="margin-bottom: 20px;color:#48a81d"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row-phone mt-4">
        <div class="align-text m-2">
            <div>
                <h5 class="border-top border-secondary pt-2">
                    <img alt="" src="assets/images/data.png"> Data Upload Feature
                </h5>
                <div class="mt-3 ">
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Data entry form
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Excel Bulk Upload (General, Amazon and Ebay)
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Autofill of consignee and item details
                    </div>
                </div>
                <h5 class="mt-3">
                    <img alt="" src="assets/images/doc.png" class="font-weight-bold"> Document Download Feature
                </h5>
                <div class="mt-3 ">
                    <div style="margin-top: 1rem;font-size: 13px;">
                        PBE, RBI, EDF, CN22/23, Shipping Labels, Packinglist
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Tax/Export Invoice
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        MEIS
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        GSTR Data Download
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Analytics
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        e-BRC
                    </div>
                </div>
            </div>
            <div class="card-phone">
                <div class="card card1 text-center">
                    <p class="free-text">Free</p>
                    <i class="fa fa-check" style="color:#48a81d"></i>
                    <br>
                    <i class="fa fa-circle text-grey"></i>
                    <br>
                    <i class="fa fa-circle text-grey mb-5"></i>
                    <i class="fa fa-check mt-5" style="color:#48a81d"></i>
                    <br>
                    <i class="fa fa-circle text-grey"></i>
                    <br>
                    <i class="fa fa-circle text-grey"></i>
                    <br>
                    <i class="fa fa-circle text-grey"></i>
                    <br>
                    <i class="fa fa-circle text-grey"></i>
                    <br>
                    <i class="fa fa-circle text-grey" style="margin-bottom: 20px;"></i>
                </div>
            </div>
        </div>

        <div class="align-text m-2">
            <div>
            </div>
        </div>
    </div>
    <div class="row-phone mt-4">
        <div class="align-text m-2">
            <div>
                <h5 class="border-top border-warning pt-2">
                    <img alt="" src="assets/images/data.png"> Data Upload Feature
                </h5>
                <div class="mt-3 ">
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Data entry form
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Excel Bulk Upload (General, Amazon and Ebay)
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Autofill of consignee and item details
                    </div>
                </div>
                <h5 class="mt-3">
                    <img alt="" src="assets/images/doc.png" class="font-weight-bold"> Document Download Feature
                </h5>
                <div class="mt-3 ">
                    <div style="margin-top: 1rem;font-size: 13px;">
                        PBE, RBI, EDF, CN22/23, Shipping Labels, Packinglist
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Tax/Export Invoice
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        MEIS
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        GSTR Data Download
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Analytics
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        e-BRC
                    </div>
                </div>
            </div>
            <div class="card-phone">
                <div class="card card1 text-center">
                    <p class="basic-text">Basic</p>
                    <i class="fa fa-check" style="color:#48a81d"></i>
                    <br>
                    <i class="fa fa-check" style="color:#48a81d"></i>
                    <br>

                    <i class="fa fa-circle text-grey mb-5"></i>
                    <i class="fa fa-check mt-5" style="color:#48a81d"></i>
                    <br>
                    <i class="fa fa-circle text-grey"></i>
                    <br>
                    <i class="fa fa-circle text-grey"></i>
                    <br>
                    <i class="fa fa-circle text-grey"></i>
                    <br>
                    <i class="fa fa-circle text-grey"></i>
                    <br>
                    <i class="fa fa-circle text-grey" style="margin-bottom: 20px;"></i>
                </div>
            </div>
        </div>

        <div class="align-text m-2">
            <div>
            </div>
        </div>
    </div>
    <div class="row-phone mt-4">
        <div class="align-text m-2">
            <div>
                <h5 class="border-top border-secondary pt-2">
                    <img alt="" src="assets/images/data.png"> Data Upload Feature
                </h5>
                <div class="mt-3 ">
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Data entry form
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Excel Bulk Upload (General, Amazon and Ebay)
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Autofill of consignee and item details
                    </div>
                </div>
                <h5 class="mt-3">
                    <img alt="" src="assets/images/doc.png" class="font-weight-bold"> Document Download Feature
                </h5>
                <div class="mt-3 ">
                    <div style="margin-top: 1rem;font-size: 13px;">
                        PBE, RBI, EDF, CN22/23, Shipping Labels, Packinglist
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Tax/Export Invoice
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        MEIS
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        GSTR Data Download
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        Analytics
                    </div>
                    <div style="margin-top: 1rem;font-size: 13px;">
                        e-BRC
                    </div>
                </div>
            </div>
            <div class="card-phone">
                <div class="card card1 text-center">
                    <p class="premium-text">Premium</p>
                    <i class="fa fa-check" style="color:#48a81d"></i>
                    <br>
                    <i class="fa fa-check" style="color:#48a81d"></i>
                    <br>
                    <i class="fa fa-check mb-5" style="color:#48a81d"></i>
                    <br>

                    <i class="fa fa-check " style="color:#48a81d"></i>
                    <br>
                    <i class="fa fa-check " style="color:#48a81d"></i>
                    <br>
                    <i class="fa fa-check " style="color:#48a81d"></i>
                    <br>

                    <i class="fa fa-check " style="color:#48a81d"></i>
                    <br>
                    <i class="fa fa-check " style="color:#48a81d"></i>
                    <br>

                    <i class="fa fa-check" style="margin-bottom: 20px;color:#48a81d"></i>
                </div>
            </div>
        </div>
        <div class="align-text m-2">
            <div>
            </div>
        </div>
    </div>

    <div class="row m-0" style="padding: 0px 40px;">
        <div class="col-md-6" style="
        padding-left: 120px;
    ">
            <h5 style="margin-bottom: 0.5rem;margin-top: 0.5rem;">
                <div class="switch">
                    <label>
                        Monthly
                        <input type="checkbox" (change)="changeMthlToAnnual($event)">
                        <span class="lever"></span> Annual
                    </label>
                </div>
            </h5>
            <p style="font-size: 13px">
                20% savings with annual plan*
            </p>


        </div>
        <div class="col-md-6 m-0">
            <div class="row m-0 mt-2">
                <div class="col-md-4" style="padding: 3px">

                    <button class="btn btn-secondary btn-block"
                        style="background-color: #666666! important;">FREE</button>

                </div>
                <div class="col-md-4" style="padding: 3px">

                    <button class="btn btn-warning btn-block" *ngIf="authService.loggedIn()"
                        (click)="checkforsubscription('basic');">
                        <span *ngIf="isMonthly">₹349/- per month</span>
                        <span *ngIf="!isMonthly">₹3499/- per year</span>
                    </button>
                    <button class="btn btn-warning btn-block" *ngIf="!authService.loggedIn()" routerLink="/login">
                        <span *ngIf="isMonthly">₹349/- per month</span>
                        <span *ngIf="!isMonthly">₹3499/- per year</span>
                    </button>


                </div>
                <div class="col-md-4" style="padding: 3px">
                    <button class="btn btn-primary btn-block" *ngIf="authService.loggedIn()"
                        (click)="checkforsubscription('premium')">
                        <span *ngIf="isMonthly">₹599/- per month</span>
                        <span *ngIf="!isMonthly">₹5999/- per year</span>
                    </button>
                    <button class="btn btn-primary btn-block" *ngIf="!authService.loggedIn()" routerLink="/login">
                        <span *ngIf="isMonthly">₹599/- per month</span>
                        <span *ngIf="!isMonthly">₹5999/- per year</span>
                    </button>
                </div>
            </div>
            <p style="font-size: 13px;text-align: center;" class="mt-3">
                Prices displayed are on a per IEC basis. GST extra as applicable.
            </p>
        </div>
    </div>
    <div class="row m-0"
        style="padding: 30px;background-image: linear-gradient(to bottom, #a156e9, #a457ed, #a659f1, #a95af5, #ac5cf9, #ac5cf9, #ac5cf9, #ac5cf9, #a95af5, #a659f1, #a457ed, #a156e9);">
        <div class="col-md-9 text-white">
            <h3> <img alt="" src="assets/images/excel.png" style="padding-right: 38px;padding-left: 15px;">Customized
                Excel For Data Upload</h3>
            <div style="padding-left: 115px">
                <span style="font-size: 13px;" class="mb-2">
                    Design your own excel template for data upload
                </span>
                <br>
                <span style="font-size: 13px;" class="mb-2">
                    Include data files for different in one place
                </span>
                <br>
                <span style="font-size: 13px;" class="mb-2">
                    Upload data for all forms on the PikMyBox application such as PBEs, invoices, shipping labels and
                    RBI EDF forms
                </span>
            </div>
        </div>
        <div class="col-md-3 text-white text-center">
            <h5 class="mt-4">
                <!-- Starting at ₹ 499/- per month -->
                Starting at ₹ 699/- per month
            </h5>
            <button (click)="apiPlan()" class="btn m-auto"
                style="border-radius:50px;background: #7b42b2;padding: 10px 59px;margin-top: 18px!important;">Get a
                Quote</button>
        </div>
    </div>
    <div class="row m-0" style="padding: 30px;">
        <div class="col-md-9">
            <h3><img alt="" src="assets/images/api2.png" style="padding-right: 38px;padding-left: 20px;">API Plan</h3>
            <div style="padding-left: 115px;">

                <span style="font-size: 13px;" class="mb-2">
                    Integrate with our API to generate all forms on the PikMyBox application.
                </span>
                <br>
                <span style="font-size: 13px;" class="mb-2">
                    Customized dashboard to view and manage your account and documents.
                </span>
                <br>
                <span style="font-size: 13px;" class="mb-2">
                    Integrate with our tools such as postage price calculator and parcels tracking.
                </span>
                <br>
                <span style="font-size: 13px;" class="mb-2">
                    Send status updates and notification to your clients.
                </span>
            </div>
        </div>
        <div class="col-md-3 text-center">
            <h5 class="mt-4">
                Starting at ₹ 3/- per parcel
            </h5>
            <button (click)="apiPlan()" class="btn m-auto"
                style="border-radius:50px;background: #7b42b2;padding: 10px 59px;    margin-top: 18px!important;">Get a
                Quote</button>
        </div>
    </div>
    <div class="row text-center m-0" *ngIf="!authService.loggedIn()" style="padding: 30px;background: #2b2c40">
        <div class="col-md-8 offset-md-2">
            <h3 class="text-white">
                Get Started with a 2-week Free Trial
            </h3>
            <div class="text-center p-2 text-white">



                <div class="form-row mb-4 " style="margin-bottom: 0px !important;">
                    <div class="col-md-6">
                        <label style="float: left;"> Who are you ?</label>
                        <select class="browser-default custom-select mb-4" [(ngModel)]="userType">
                            <option value="0" disabled>Choose option</option>
                            <option value="1">Exporter</option>
                            <option value="2">Service Provider</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <label style="float: left;"> Referred By</label>

                        <select class="browser-default custom-select mb-4" [(ngModel)]="refBy"
                            [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let ref of refferals" value="{{ref.id}}">{{ref.name}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-row mb-4">

                    <div class="col-md-6">
                        <!-- First name -->
                        <label style="float: left;"> Mobile No.</label>
                        <input type="text" id="" name="mobile" class="form-control" [(ngModel)]="mobile"
                            placeholder="Mobile No" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="col-md-6">
                        <!-- Last name -->
                        <label style="float: left;">Email Id </label>
                        <input type="text" id="" name="email" class="form-control" [(ngModel)]="email"
                            placeholder="Email Id" [ngModelOptions]="{standalone: true}">
                    </div>
                </div>


                <!-- Sign up button -->
                <button class="btn btn-primary my-4 formButton" type="submit" (click)="register()">Register</button>

            </div>
        </div>

    </div>
    <div class="row text-center m-0" style="padding: 30px;background: #25263a">
        <div class="col-md-8 offset-md-2">
            <h6 class="text-white">
                Please review our <a routerLink="/termsofuse" style="color:#ff0066;"> Terms of Use</a>*
            </h6>
        </div>
    </div>
</div>


<!-- Central Modal Small -->
<div class="modal" id="upgradePlan" tabindex="-1" role="dialog" aria-labelledby="upgradePlanLabel" aria-hidden="true">

    <!-- Change class .modal-sm to change the size of the modal -->
    <div class="modal-dialog modal-dialog-centered" role="document">


        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="upgradePlanLabel">Are you sure want to change your plan?</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p id="diffText">

                </p>
            </div>
            <div class="modal-footer" *ngIf="currentPlan">
                <button type="button" class="btn btn-success btn-sm" data-dismiss="modal" (click)="upgradePlan('Yes')"
                    *ngIf="isUpgrade">Yes</button>
                <button type="button" *ngIf="isChangePlan" id="nextMonthbtn" class="btn btn-secondary btn-sm"
                    data-dismiss="modal" (click)="upgradePlan('Next Month')">Start Next
                    <span
                        *ngIf="(isMonthly && currentPlan.planType=='monthly') || (!isMonthly && currentPlan.planType=='monthly')">Month</span>
                    <span
                        *ngIf="(!isMonthly && currentPlan.planType=='yearly') || (isMonthly && currentPlan.planType=='yearly')">Year</span>
                    <!-- {{isMonthly}} {{currentPlan.planType}} -->
                </button>
                <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<!-- Central Modal Small -->