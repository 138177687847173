import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-generateinvoice',
  templateUrl: './generateinvoice.component.html',
  styleUrls: ['./generateinvoice.component.css']
})
export class GenerateinvoiceComponent implements OnInit {
  fpoCode = ''
  invoiceTerms = ''
  imageName: any = "";
  profileData: any;
  imgUrl = '';
  errMsg = '';
  data: any = [];
  currentUrl: any = "";
  exporterIec: any;
  itemList: any = [];
  totalAmount: number = 0;
  igstTotal: number = 0;
  paidAmount: number = 0;
  dd;
  mm;
  yyyy;
  isLoading = false;
  today;
  extraInfo: any = "";
  heading: any = "";
  amountInWrd: any = "";
  quantity: any;
  amount: any;
  gstMode: any = "Not Applicable";
  salesBasis: any = "Advance Payment";
  descHeading: any = "";
  description: any = "";
  descValue: any = "";
  valueHeading: any = "";
  transactionId: any = "";
  orderid: any;
  image: any;
  boxData: any;

  constructor(
    public FormBuilder: FormBuilder,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public service: AuthService,
    private nfs: NotificationService
  ) { }

  ngOnInit() {

    this.currentUrl = this.activatedRoute.snapshot.params;
    this.generatePi(this.currentUrl.id);

    if (this.service.getuserProfile().userType == '1') {
      this.getLogoForInvoice();
    }
  }

  getLogoForExporter(ieCode) {
    this.service.getLogoForExporter({ ieCode: ieCode }).subscribe(response => {
      if (response.success == true) {
        this.imgUrl = response.data;
      }
      else {
        this.errMsg = "Please upload your logo";
      }
    })
  }


  getLogoForInvoice() {
    this.service.getLogoForInvoice().subscribe(response => {
      if (response.success == true) {
        this.imgUrl = response.data;
      }
      else {
        this.errMsg = response.message;
      }
    })
  }

  generatePi(orderId) {
    this.service.getdatabyboxforinvoice({
      boxId: orderId.toUpperCase()

    }).subscribe(response => {
      if (response.success == true) {

        this.data = response.result;
        this.boxData = response.boxDetails;
        // console.log(this.data);
        var chaNo
        if (this.service.getuserProfile().userType != 1) {
          chaNo = this.service.getuserProfile().chaNo
        }
        this.gstMode = this.data[0].igstPayment;
        this.invoiceTerms = this.data[0].invoiceTerms;
        for (var i = 0; i < this.data.length; i++) {
          this.itemList.push({
            exporterName: this.boxData.exporterDetails.name, exporterAddress: this.boxData.exporterDetails.address, ieCode: this.boxData.exporterDetails.ieCode, gstin: this.boxData.exporterDetails.gstin,
            consigneeName: this.boxData.consigneeDetails.name, consigneeAddress: this.boxData.consigneeDetails.address, consigneeCity: this.boxData.consigneeDetails.city, consigneeZip: this.boxData.consigneeDetails.zipCode,
            consigneeCountry: this.boxData.consigneeDetails.country, invoiceNo: this.data[i].invoiceNo, invoiceDate
              : this.data[i].invoiceDate, currency: this.data[i].paymentDetails.currency,
            serialNo: this.data[i].slNo, description: this.data[i].description, hsnCode: this.data[i].hsnCode, quantity: this.data[i].quantity.number, unit: this.data[i].quantity.unit, rate: (this.data[i].paymentDetails.fob_foreign / this.data[i].quantity.number).toFixed(2),
            amount: this.data[i].paymentDetails.fob_foreign, igst: this.data[i].igstDetails.rate, igstAmount: this.data[i].igstDetails.amount / this.data[i].paymentDetails.exchangeRate, chaLicenseNo: chaNo
          });

          this.totalAmount = this.totalAmount + Number(this.data[i].paymentDetails.fob_foreign);
          this.igstTotal = this.data[i].igstDetails.rate;
          this.paidAmount = this.data[i].igstDetails.amount;
          this.transactionId = this.data[0].trxnId;

        }
        this.orderid = this.data[0].orderId;
        if (this.service.getuserProfile().userType != '1' && this.gstMode == '') {
          this.getExporterProfile(this.boxData.exporterDetails.ieCode)
          this.getLogoForExporter(this.boxData.exporterDetails.ieCode)
        }
      }
      else {
        if (response.success == false) {
          this.nfs.showInfo('error', response.message, 'error');
        }
      }

    })

  }


  // quantityChange(t,i)
  // {


  //     this.itemList[i].amount=this.itemList[i].rate*this.itemList[i].quantity
  //     this.itemList[i].igstAmount=(this.itemList[i].igst*this.itemList[i].amount)/100
  //     this.itemList[i].rate=(this.itemList[i].amount/this.itemList[i].quantity)
  //     // this.itemList[i].igstAmount=(this.itemList[i].rate*this.itemList[i].amount)/100

  //     this.totalAmount=0
  //     this.igstTotal=0
  //     this.paidAmount=0

  //     for(var j=0;j<this.itemList.length;j++)
  //     {
  //     this.totalAmount=this.totalAmount+Number(this.itemList[j].amount);
  //     this.igstTotal=this.igstTotal+this.itemList[j].igstAmount;
  //     this.paidAmount=this.totalAmount+this.igstTotal;
  //     }

  // }
  getProfile() {
    this.service.getProfileDetails().subscribe(response => {
      if (response.success == true) {
        this.profileData = response.data.authSignatory;
        this.invoiceTerms = response.data.invoiceTerms
        //  this.pan =response.data.pan
        this.salesBasis = response.data.invoiceTerms
        this.gstMode = response.data.igstPayment
        this.fpoCode = response.data.fpoCode
        //  if(this.profileData.length>0){
        //    this.getsignatoryimage(this.profileData[0].file);
        //  }
        console.log(this.gstMode)
      }
      else {
        this.nfs.showInfo('Oops !', response.message, 'error');
      }
    })
  }

  getExporterProfile(ieCode) {
    this.service.checkIeCodeForDataEntry(ieCode).subscribe(response => {
      if (response.success == true) {
        this.profileData = response.data1.authSignatory;
        this.invoiceTerms = response.data1.invoiceTerms
        this.salesBasis = response.data1.invoiceTerms
        this.gstMode = response.data1.igstPayment
        this.fpoCode = response.data1.fpoCode
        console.log(this.gstMode)
      }
      else {
        this.nfs.showInfo('Oops !', response.message, 'error');
      }
    })
  }

  printOrder(p) {
    this.isLoading = true
    var data = {
      data: this.itemList, totalAmount: this.totalAmount, igstTotal: this.igstTotal, paidAmount: this.paidAmount, heading: this.heading, extraInfo: this.extraInfo,
      amountInWrd: this.amountInWrd, description: this.description, descValue: this.descValue, gstMode: this.gstMode, salesBasis: this.salesBasis, valueHeading: this.valueHeading, descHeading: this.descHeading, orderId: this.orderid, parcelId: this.currentUrl.id, transactionId: this.transactionId
    }

    this.service.downloadAsPdf({ type: "invoice", data: data }).subscribe(data => {
      if (data.success == true) {
        const blob = new Blob([new Uint8Array(data.link.data)], { type: "application/pdf" })
        var url = URL.createObjectURL(blob);
        this.isLoading = false
        if (p == 'view') {
          window.open(url, '_blank')
          this.router.navigate(['invoice'])
        }
        else {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          this.today = new Date();
          this.dd = this.today.getDate();
          this.mm = this.today.getMonth() + 1; //January is 0!
          this.yyyy = this.today.getFullYear();

          if (this.dd < 10) {
            this.dd = '0' + this.dd;
            this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
          }

          if (this.mm < 10) {
            this.mm = '0' + this.mm;
            this.today = this.dd + '-' + this.mm + '-' + this.yyyy;
          }
          a.download = "Invoice" + this.today + ".pdf";
          a.click();
          window.URL.revokeObjectURL(url);
        }
      }
      else {
        this.nfs.showInfo('Oops!', data.message, 'error');
      }

      this.isLoading = false;

    })
  }


  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
    }
    myReader.readAsDataURL(file);

  }

  convertDate(date) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(date);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-');
  }

}
