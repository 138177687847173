import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { io } from "socket.io-client";

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private BASE_URL = environment.socketUrl;
  private socket;

  constructor() { }

  /*
  * Method to connect the users to socket
  */
  connectSocket(userId: string): void {
    // this.socket = io(this.BASE_URL, { query: `userId=${userId}`, transports: ['polling'] });
    this.socket = io(this.BASE_URL, {
      // withCredentials: false,
      // transports: ["websocket", "polling"],
      query: { userId: userId },
    });
  }

  listenSocketEvents(eventName): Observable<any> {
    return new Observable(observer => {
      this.socket.on(eventName, (data) => {
        console.log("received data >> ", eventName, data);

        observer.next(data);
      });
    });
  }

  emitSocketEvents(evtType, message) {
    this.socket.emit(evtType, message);
  }


  /*
  * Method to emit the logout event.
  */

  logout(userId: { userId: string }): Observable<any> {
    // console.log(userId)
    this.socket.emit('logout', userId);
    return new Observable(observer => {
      this.socket.on('logout-response', (data: any) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
  }

  pickup(obj): void {
    // console.log(obj)
    this.socket.emit('pickup', obj);
  }

  pickupRespose(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('pickup-response', (data) => {
        // console.log(data)
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
  }

  // logoutUser(userId: { userId: string}): Observable<Auth> {
  // 	console.log(userId)
  // 	this.socket.emit('logoutUser', userId);
  // 	return new Observable(observer => {
  // 		this.socket.on('logout-user-response', (data: Auth) => {
  // 			observer.next(data);
  // 		});
  // 		return () => {
  // 			this.socket.disconnect();
  // 		};
  // 	});
  // }

  /*
  * Method to receive chat-list-response event.
  */
  getChatList(userId: string = null): Observable<any> {
    if (userId !== null) {
      this.socket.emit('chat-list', { userId: userId });
    }
    return new Observable(observer => {
      this.socket.on('chat-list-response', (data: any) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
  }

  /*
* Method to emit the add-messages event.
*/
  sendMessage(message: any): void {
    this.socket.emit('add-message', message);
  }

  sendTypingAlert(typing: any): void {
    // console.log('typing')
    this.socket.emit('typing', typing);
  }

  /*
* Method to receive add-message-response event.
*/
  receiveMessages(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('add-message-response', (data) => {
        console.log("add-message-response >>> ", data);

        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
  }

  onlineOffline(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('online-offline-response', (data) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
  }

  receiveTypingAlerts(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('typing-response', (data) => {
        // console.log(data)
        observer.next(data);
      });

      return () => {
        this.socket.disconnect();
      };
    });
  }

}
