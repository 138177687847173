<div class="wrapper" style="margin-top:0px">
    <div class="head">
        <div class="container" style="    margin-right: auto;
            margin-left: auto;
            padding-left: 15px;
            padding-right: 15px;">
            <div class="jumbotron">
                <h1>REST API</h1>
                <p class="lead">Simplest and convenient PMB integration.</p>
                <p></p>
            </div>
        </div>
    </div>

    <div class="container">

        <div class="row">
            <div class="col-md-3 col-xs-12" style="margin-bottom:30px">
                <div class="list-group" id="list-tab" role="tablist">
                    <a class="list-group-item list-group-item-action btn active" id="list-home-list" data-toggle="list"
                        href="#list-home" role="tab" aria-controls="home">Introduction</a>
                    <a class="list-group-item list-group-item-action btn" id="list-api-list" data-toggle="list"
                        href="#list-api" role="tab" aria-controls="home">Get Your API key</a>
                    <a class="list-group-item list-group-item-action btn" id="list-profile-list" data-toggle="list"
                        href="#list-profile" role="tab" aria-controls="profile">Saving parcel(s)</a>
                    <!-- <a class="list-group-item list-group-item-action btn" id="list-messages-list" data-toggle="list" href="#list-messages"
              role="tab" aria-controls="messages">Messages</a>
            <a class="list-group-item list-group-item-action btn" id="list-settings-list" data-toggle="list" href="#list-settings"
              role="tab" aria-controls="settings">Settings</a> -->
                    <a class="list-group-item list-group-item-action btn" id="list-currency-list" data-toggle="list"
                        href="#list-currency" role="tab" aria-controls="currency">Currencies with codes
                    </a>
                    <a class="list-group-item list-group-item-action btn" id="list-shipping-list" data-toggle="list"
                        href="#list-shipping" role="tab" aria-controls="shipping">Shipping Methods
                    </a>
                    <a class="list-group-item list-group-item-action btn" id="list-postage-list" data-toggle="list"
                        href="#list-postage" role="tab" aria-controls="postage">Calculate Postage
                    </a>
                    <a class="list-group-item list-group-item-action btn" id="list-category-list" data-toggle="list"
                        href="#list-category" role="tab" aria-controls="category">Product Categories
                    </a>
                </div>
            </div>
            <div class="col-md-9">
                <div class="card">
                    <!-- Card image -->

                    <div class="tab-content" id="nav-tabContent">


                        <div class="tab-pane fade show active" id="list-home" role="tabpanel"
                            aria-labelledby="list-home-list">
                            <img class="card-img-top" src="/assets/img/intro.png" style="height:97px"
                                alt="Card image cap">
                            <h4 class="card-title textShadow">
                                Introduction
                            </h4>
                            <div class="card-body" style="max-height: 389px;overflow-x: auto;">

                                <p class="card-text">
                                    Before you read this document, we advise you to go through API Overview where you
                                    can information on how to create a PikMyBox merchant account, what integration model
                                    to choose and how to test your integration.

                                </p>
                                <br />
                                <h4 class="card-title">
                                    Protocol version
                                </h4>
                                <p class="card-text">
                                    <img src="/assets/img/1_1.jpg" style="width:100%; height:auto" alt="">
                                    This document describes integration with the REST API 2.1 protocol. It presents
                                    various methods of implementing online payments via different PikMyBox services and
                                    is dedicated primarily to developers wanting to implement the PikMyBox payment
                                    services.

                                    For information on older versions of the API, email PikMyBox technical support
                                    tech@pikmybox.com.


                                </p>

                            </div>
                        </div>
                        <div class="tab-pane fade" id="list-api" role="tabpanel" aria-labelledby="list-api-list">
                            <img class="card-img-top" src="/assets/img/intro.png" style="height:97px"
                                alt="Card image cap">
                            <h4 class="card-title textShadow">
                                Get your API key
                            </h4>

                            <div class="card-body" style="max-height: 389px;overflow-x: auto;">

                                <div class="form-control" *ngIf="apiKey?.length>10">
                                    <span class="text-default">YOUR API KEY:</span>
                                    <hr style="margin:4px 0px">
                                    <span class="unselectable">{{apiKey}}</span>
                                </div>
                                <div class="text-center mt-3">
                                    <button class="btn btn-outline btn-default" *ngIf="apiKey?.length>10"
                                        (click)="copyKeyToCP()">{{buttonText}}</button>
                                    <button class="btn btn-outline btn-amber" *ngIf="apiKey?.length>10"
                                        (click)="genYourApi('1')">Re-Generate API Key</button>
                                    <button class="btn btn-outline btn-primary" *ngIf="!apiKey?.length"
                                        (click)="genYourApi('0')">Generate API Key</button>
                                </div>
                                <p class="card-text text-danger mt-3">
                                    Note* - Never share your API key with anyone, we never ask for API key/password.
                                    Sharing your API key may give access to your account.
                                </p>


                            </div>
                        </div>
                        <div class="tab-pane fade" id="list-profile" role="tabpanel"
                            aria-labelledby="list-profile-list">



                            <img class="card-img-top" src="/assets/img/intro.png" style="height:97px"
                                alt="Card image cap">
                            <h4 class="card-title textShadow">
                                Saving parcel(s)
                            </h4>
                            <div class="card-body" style="max-height: 389px;overflow-x: auto;">

                                <span>
                                    <p style="margin-bottom:2px"><strong>Method</strong> : POST</p>
                                </span>
                                <span>
                                    <p style="margin-bottom:2px"><strong>Host/Url (Prod)</strong> :
                                        https://www.pikmybox.com/api/v1/client/saveorderdata</p>
                                </span>
                                <span>
                                    <p style="margin-bottom:2px"><strong>Host/Url (Test)</strong> :
                                        http://sandbox.pikmybox.com/api/v1/client/saveorderdata</p>
                                </span>
                                <span>
                                    <p style="margin-bottom:2px"><strong>Content-Type</strong> : application/json</p>
                                </span>
                                <span>
                                    <p style="margin-bottom:2px"><strong>Payload/Body :</strong></p>
                                </span>

                                <!-- <pre [innerHtml]="circularObj | prettyjson:3"></pre> -->
                                <table class="table table-bordered">
                                    <tr>
                                        <td colspan="2">
                                            <h6 class="text-left" style="font-weight: 700;">
                                                Parameters
                                            </h6>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>authKey</th>
                                        <td class="text-left">
                                            <span class="grey-text">String</span>
                                            <span class="text-danger" style="font-weight: 600;"> Required</span>
                                            <br>
                                            <p>
                                                API key that can be generated from Tools > REST API > GET YOUR API KEY.
                                                <br>
                                                <span style="font-style: italic;">
                                                    Ex.- 3ba1891d8fee90c87e0e95f9f8126180...
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>orderData</th>
                                        <td class="text-left">
                                            <span class="grey-text">Array of Objects</span>
                                            <span class="required" style="font-weight: 600;"> Required</span>
                                            <br>
                                            <p>
                                                Array of Objects. Each object contains data against an order.
                                                <br>
                                                <span style="font-style: italic;">
                                                    Ex.- {{'[ { } , { } ]'}}
                                                </span>
                                            </p>
                                            <table class="table table-bordered">
                                                <tr>
                                                    <th>pbe</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Type of postal bill of export (PBE)
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- PBE1 or PBE2
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>description</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Product/Item Description
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- Mobile Back Cover
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>packageDesc</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Summary of parcel/shipment
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- Mobile Accessories
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>otherCharge</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            If You have taken any other charge of Item/goods from the
                                                            consignee (in INR)
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 10
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>hsnCode</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Products HSN Code for more details.
                                                            <a routerLink="/hsnlookup"
                                                                style="color: blue;cursor: pointer; text-decoration: underline;">Click
                                                                here
                                                            </a>
                                                            to find HSN Code
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 123456
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>unit</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Unit of Item/parcel for more details. Click here
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- PEICES or GRAMS or METER
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>quantity</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Quantity of Product/Item/Goods.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 1 or 5 or 10 or 10.25 or any number
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>compensationCess_rate</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Compensation Cess Rate in % if applicable.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 1,2,3,4, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>igstDetails_rate</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            IGST rate in % if applicable
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 5,12,18,28, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>customDuties_CessRate</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Custom Duty Cess in % if applicable.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 1,2,5, etc
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>itemNetWeight</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Net Weight of Item/article/Goods in Grams.

                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 100,200,230, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>itemGrossWeight</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Gross weight of Item/Article/Goods in Grams.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 100,200,300, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>exportDutyRate</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Export duty rate in % if applicable.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 1,2,3,5, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>freight</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Freight/shipping amount in INR if applicable.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 10,20,100, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>insurance</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Insurance of Item/parcel/shipment if applicable.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 10,20,35, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>dutyCess_rate</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Duty Cess rate in % if applicable.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 1,2,6,10, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>grossWeight</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Total Gross weight of shipment including packaging
                                                            weight in grams.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 125, 250, 350, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>category</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            <span (click)="goTo('list-category-list')"
                                                                class="text-primary">Category </span>of item. Refer to
                                                            List of Category in the
                                                            Appendix page.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- PIECES, GRAMS, METER, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>url</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="conditional" style="font-weight: 600;">
                                                            Conditional</span>
                                                        <br>
                                                        <p>
                                                            Website name, mandatory for PBE 1
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- www.amazon.com, www.ebay.com, www.pikmybox.com, etc
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>parcelType</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Shipping method of your parcel. Refer to <span
                                                                (click)="goTo('list-shipping-list')"
                                                                class="text-primary">List of
                                                                Shipping method</span> in the Appendix page

                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- Air Parcel (CD), EMS Speed Post, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>trxnId</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="conditional"
                                                            style="font-weight: 600;">Conditional</span>
                                                        <br>
                                                        <p>
                                                            Transaction ID of your order. Transaction key is
                                                            usually generated after the successful payment
                                                            through your Bank/Payment Gateway. Mandate in
                                                            case of PBE 1.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- TRXN1212356, 1245454, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>orderId</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            An Order ID is the number system that you can uses
                                                            exclusively to keep track of your orders.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- OD12345678, 787878, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>sku</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="conditional" style="font-weight: 600;">
                                                            Optional</span>
                                                        <br>
                                                        <p>
                                                            Stock Keeping Unit (SKU) is a number assigned to a
                                                            product by a retail store to identify the price,
                                                            product options and manufacturer of the
                                                            merchandise. Mandate in case of PBE I.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 565656, ABCD121245, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>consigneeName</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Consignee Name.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- James Brownie, Laura, Md. Akran, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>consigneeBusinessName</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="optional" style="font-weight: 600;">
                                                            Optional</span>
                                                        <br>
                                                        <p>
                                                            Consignee Business Name, if available
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- ABC LLC, MNC INC., etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>consigneeImporterCode</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="optional" style="font-weight: 600;">
                                                            Optional</span>
                                                        <br>
                                                        <p>
                                                            Consignee Importer Code, if available .
                                                            .
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 9898998, ADS45454, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>consigneeAddress</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Consignee Address.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 95, Wagon street, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>consigneeCity</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Consignee city
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- New York City, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>consigneeState</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="optional" style="font-weight: 600;">
                                                            Optional</span>
                                                        <br>
                                                        <p>
                                                            Consignee State, if available.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- New York, Ontario, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>consigneeZipCode</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Consignee zip/pin code.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 1211212,78878
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>consigneeCountry</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Consignee Country. Refer to Country List in the
                                                            Appendix page
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- USA, Canada, Japan, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>consigneeEmail</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="optional" style="font-weight: 600;">
                                                            Optional</span>
                                                        <br>
                                                        <p>
                                                            Consignee Email, if available .
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- abc@pikmybox.com, xyz@gmail.com, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>consigneeMobile</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="optional" style="font-weight: 600;">
                                                            Optional</span>
                                                        <br>
                                                        <p>
                                                            Consignee Mobile, if availab.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- +14255551212, (425)555-1212, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>


                                                <tr>
                                                    <th>fob_foreign</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Invoice amount of particular item of your
                                                            shipment/Article in Foreign currency.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 10, 20, 25, 555, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>currency</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Foreign currency. Refer to <span
                                                                (click)="goTo('list-currency-list')"> Currency list in
                                                                the
                                                                Appendix page.
                                                            </span>

                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- USD, KRW, CAD, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>invoiceNo</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Invoice number.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- INV-001, I0001, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>invoiceDate</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Invoice Date (“DD-MM-YYYY”).
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 21-06-2018, 09-08-2019, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>invoiceTerms</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Payment terms. Refer to Invoice Terms below.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                1. Advance Payment. <br>
                                                                2. Payment term 90 days. <br>
                                                                3. Payment term 30 days. <br>
                                                                4. Payment term 15 days.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>igstMode</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Mode of IGST payment. Refer to Mode of IGST below.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                1. Not Applicable <br>
                                                                2. Export with Payment of IGST <br>
                                                                3. Export under LUT/Bond
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>serialNo</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Serial number of item as on invoice..
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 1,2,3,4, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>boxId</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="optional" style="font-weight: 600;">
                                                            Optional</span>
                                                        <br>
                                                        <p>
                                                            Box Id is define the No. of Parcel. If In one parcel
                                                            have multiple item them Box Id is same and for
                                                            every different parcel box id is different. If not supplied
                                                            box Id is created automatically & every item without box id
                                                            is treated as separate parcel.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- 1,2,3, ABC123, BX1212, 545, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>trackingNo</th>
                                                    <td class="text-left">
                                                        <span class="grey-text">String</span>
                                                        <span class="text-danger" style="font-weight: 600;">
                                                            Required</span>
                                                        <br>
                                                        <p>
                                                            Tracking No provided by the India Post.
                                                            <br>
                                                            <span style="font-style: italic;">
                                                                Ex.- EM824487299IN, CD787878787IN, etc.
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>

                                            </table>
                                        </td>
                                    </tr>
                                </table>

                                <span>
                                    <p style="margin-bottom:2px"><strong>Sample Data :</strong></p>
                                </span>

                                <pre [innerHtml]="sampleData  "></pre>
                                <!-- <pre [innerHtml]="sampleData | prettyjson:3"></pre> -->
                            </div>
                        </div>

                        <div class="tab-pane fade" id="list-messages" role="tabpanel"
                            aria-labelledby="list-messages-list" style="max-height: 463px;overflow-x: auto;">
                            <img class="card-img-top" src="/assets/img/intro.png" style="height:97px"
                                alt="Card image cap">
                            <h4 class="card-title textShadow">
                                Saving a new order
                            </h4>
                            <div class="card-body">
                                <p class="card-text">
                                    Before you read this document, we advise you to go through API Overview where you
                                    can information on how to create a PikMyBox merchant account, what integration model
                                    to choose and how to test your integration.

                                </p>

                                <pre>
                   <code>
                      &#123;
                      "type":"pbe",
                      "tempSBill":"PMB1547276419714"
                      &#125;
                </code>
               </pre>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="list-settings" role="tabpanel"
                            aria-labelledby="list-settings-list" style="max-height: 463px;overflow-x: auto;">
                            <img class="card-img-top" src="/assets/img/intro.png" style="height:97px"
                                alt="Card image cap">
                            <h4 class="card-title textShadow">
                                Saving a new order
                            </h4>
                            <div class="card-body">
                                <p class="card-text">
                                    Before you read this document, we advise you to go through API Overview where you
                                    can information on how to create a PikMyBox merchant account, what integration model
                                    to choose and how to test your integration.

                                </p>

                                <pre>
                   <code>
                      &#123;
                      "type":"pbe",
                      "tempSBill":"PMB1547276419714"
                      &#125;
                </code>
               </pre>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="list-currency" role="tabpanel"
                            aria-labelledby="list-currency-list">
                            <img class="card-img-top" src="/assets/img/intro.png" style="height:97px"
                                alt="Card image cap">
                            <h4 class="card-title textShadow">
                                Currencies with their respected codes
                            </h4>
                            <div class="card-body" style="max-height: 389px;overflow-x: auto;">
                                <h4 class="card-title">
                                    Currently we support 22 currencies :
                                </h4>

                                <table class="table table-bordered m-b-0 text-center" id="mainTable">
                                    <thead>
                                        <tr>
                                            <th>Sl. No.</th>
                                            <th>Currency Name</th>
                                            <th>Short Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td style="padding: 0">Australian Dollar</td>
                                            <td>AUD</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td style="padding: 0">Bahrain Dinar</td>
                                            <td>BHD</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td style="padding: 0">Canadian Dollar</td>
                                            <td>CAD</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td style="padding: 0">Chinese Yuan</td>
                                            <td>CNY</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td style="padding: 0">Danish Kroner</td>
                                            <td>DKK</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td style="padding: 0">EURO</td>
                                            <td>EUR</td>

                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td style="padding: 0">Hong Kong Dollar</td>
                                            <td>HKD</td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td style="padding: 0">Japanese Yen</td>
                                            <td>JPY</td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td style="padding: 0">Korean Won</td>
                                            <td>KRW
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td style="padding: 0">Kuwait Dinar</td>
                                            <td>KWD</td>
                                        </tr>

                                        <tr>
                                            <td>11</td>
                                            <td style="padding: 0">New Zealand Dollar</td>
                                            <td>NZD</td>
                                        </tr>
                                        <tr>
                                            <td>12</td>
                                            <td style="padding: 0">Norwegian Kroner</td>
                                            <td>NOK</td>
                                        </tr>
                                        <tr>
                                            <td>13</td>
                                            <td style="padding: 0">Pound Sterling</td>
                                            <td>GBP</td>
                                        </tr>

                                        <tr>
                                            <td>14</td>
                                            <td style="padding: 0">Qatari Riyal</td>
                                            <td>QAR</td>
                                        </tr>

                                        <tr>
                                            <td>15</td>
                                            <td style="padding: 0">Saudi Arabian Riyal</td>
                                            <td>SAR</td>
                                        </tr>
                                        <tr>
                                            <td>16</td>
                                            <td style="padding: 0">Singapore Dollar</td>
                                            <td>SGD</td>
                                        </tr>
                                        <tr>
                                            <td>17</td>
                                            <td style="padding: 0">South African Rand</td>
                                            <td>ZAR</td>
                                        </tr>
                                        <tr>
                                            <td>18</td>
                                            <td style="padding: 0">Swedish Kroner
                                            </td>
                                            <td>SEK</td>
                                        </tr>
                                        <tr>
                                            <td>19</td>
                                            <td style="padding: 0">Swiss Franc</td>
                                            <td>CHF</td>
                                        </tr>
                                        <tr>
                                            <td>20</td>
                                            <td style="padding: 0">Turkish Lira</td>
                                            <td>TRY</td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td style="padding: 0">UAE Dirham</td>
                                            <td>AED</td>
                                        </tr>
                                        <tr>
                                            <td>22</td>
                                            <td style="padding: 0">US Dollar</td>
                                            <td>USD</td>
                                        </tr>
                                    </tbody>
                                </table>


                            </div>
                        </div>

                        <div class="tab-pane fade" id="list-shipping" role="tabpanel"
                            aria-labelledby="list-shipping-list">
                            <img class="card-img-top" src="/assets/img/intro.png" style="height:97px"
                                alt="Card image cap">
                            <h4 class="card-title textShadow">
                                Shipping Methods (Used by INDIA POST Only)
                            </h4>
                            <div class="card-body" style="max-height: 389px;overflow-x: auto;">
                                <h4 class="card-title">
                                    Below are Four Types of methods used by INDIA POST

                                </h4>

                                <ul>
                                    <li>Air Parcel (CD)</li>
                                    <li>EMS Speed Post</li>
                                    <li>Int’l Small Packets (RD)</li>
                                    <li>Int’l Tracked Packets</li>
                                </ul>


                            </div>
                        </div>

                        <div class="tab-pane fade" id="list-category" role="tabpanel"
                            aria-labelledby="list-category-list">
                            <img class="card-img-top" src="/assets/img/intro.png" style="height:97px"
                                alt="Card image cap">
                            <h4 class="card-title textShadow">
                                Product Categories
                            </h4>
                            <div class="card-body" style="max-height: 389px;overflow-x: auto;">
                                <h4 class="card-title">
                                    List of Categories :
                                </h4>

                                <table class="table table-bordered m-b-0 text-center" id="mainTable">
                                    <thead>
                                        <tr>
                                            <th>Sl. No.</th>
                                            <th>Category Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let cat of categories;let i=index">
                                            <td>{{i+1}}</td>
                                            <td>{{cat.categoryName}}</td>
                                        </tr>

                                    </tbody>
                                </table>


                            </div>
                        </div>

                        <div class="tab-pane fade" id="list-postage" role="tabpanel"
                            aria-labelledby="list-postage-list">
                            <img class="card-img-top" src="/assets/img/intro.png" style="height:97px"
                                alt="Card image cap">
                            <h4 class="card-title textShadow">
                                Calculate Shipping Charges/Postage
                            </h4>
                            <div class="card-body" style="max-height: 389px;overflow-x: auto;">

                                <span class="card-text">
                                    For this you can use our postage calculator by logging into you PMB account.
                                    Link for postage calculator <a href="https://www.pikmybox.com/ratecalculator/new"
                                        target="new">https://www.pikmybox.com/ratecalculator/new</a>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

    </div>
</div>