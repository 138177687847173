import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PagerService } from 'src/app/services/pager.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

declare var $: any;
var ToasterConfig: any;

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {

  public toasterService: any;
  selectedTicketId = '';
  taskDetails: any;
  priority = 'Medium'
  pmsUsers = [];
  selectedTicket;
  currentUser;
  ticketsData = [];
  currentPage = 1
  images: any;
  deadLine;
  comments: any = [];
  desc = '';
  title = ''
  mycomment = '';
  selectedUser = []
  ticketId: any;
  modal = false;
  description: any;
  tableHeading = 'Pending';
  pageSize = 20;
  pager: any = {};
  pagedItems: any[];
  count = 0;
  slideIndex = 1;
  labelText: any;
  tid: any;
  searchText = 'All';

  public myDatePickerOptions = {
    dateFormat: 'dd.mm.yyyy',
    dateRange: false,
    markCurrentDay: true,
  }

  userSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  public config: any;
  constructor(public authService: AuthService, public pagerService: PagerService,
    private nfs: NotificationService) {
    // this.config = new ToasterConfig({ positionClass: 'toast-bottom-right' })
  }

  ngOnInit() {
    this.setPage(1);
    this.getPmsUsers();
  }

  getValue(val) {
    this.tableHeading = val;
    this.setPage(1);
  }



  getTickets(page) {
    this.ticketsData = [];
    this.authService.gettickets({ status: this.tableHeading, page: page, type: this.searchText }).subscribe(response => {
      if (response.success == true) {
        if (response.data.length > 0) {
          this.ticketsData = response.data;
        }
        else {
          this.toasterService.pop('warning', '', 'No Record Found');
        }
      }
      else {
        this.toasterService.pop('warning', '', response.message);
      }
    })
  }

  onItemSelect(item: any) {
    $('.c-input').val('')
  }

  OnItemDeSelect(item: any) {
    // $('.c-input').val()
    // console.log("​---OnItemDeSelect", $('.c-input').val())
  }

  onSelectAll(items: any) {
    $('.c-input').val('')
    // console.log("​---onSelectAll", $('.c-input').val())
  }

  onDeSelectAll(items: any) {
    // $('.c-input').val()
    // console.log("​---onDeSelectAll", $('.c-input').val())
  }

  getPmsUsers() {
    this.authService.getPmsUsers().subscribe(data => {
      if (data.success) {
        var allUsers = data.data
        this.pmsUsers = data.data
        allUsers.push({
          "_id": "5c17921ec2055a4b7c17f6b6",
          "name": "Uddhav Kumar",
          "email": "uddhav@lynkit.in",
          "code": "LT0001",
        })
        allUsers.push({
          "_id": "5c17921ec2055a4b7c17f6b7",
          "name": "Sadhika Kumar",
          "email": "sadhika@lynkit.in",
          "code": "LT0002"
        })
        this.pmsUsers = this.pmsUsers.filter((e) => e.email != this.authService.getuserProfile().email)
        this.currentUser = allUsers.filter((e) => e.email == this.authService.getuserProfile().email)[0]

        this.pmsUsers.map(item => {
          item.id = item.code;
          item.itemName = item.name;
          return item;
        })
        // this.userList = data;
        this.pmsUsers.sort(function (a, b) {
          var nameA = a.itemName.toLowerCase(), nameB = b.itemName.toLowerCase()
          if (nameA < nameB) //sort string ascending
            return -1
          if (nameA > nameB)
            return 1
          return 0 //default return value (no sorting)
        })


        // console.log(this.pmsUsers)
        // console.log(this.currentUser)
      }
      else {
        this.pmsUsers = []
      }
    })
  }

  setPage(page: number) {
    this.authService.getTicketsCount({ status: this.tableHeading, type: this.searchText }).subscribe(response => {
      if (response.success == true) {
        this.count = response.data;
        // get pager object from service
        this.pager = this.pagerService.getPager(this.count, page, 20);
        // get current page of items
        this.currentPage = page
        this.getTickets(page);
      }
      else {
        this.nfs.showInfo('Oops !', response.message, 'error');
        this.pager = this.pagerService.getPager(0, page, 20);
      }
    });
  }

  checkFileExtn(file) {
    if (file.includes(".png") || file.includes('.jpg') || file.includes('.jpeg') || file.includes('.gif') || file.includes('.bpm')) {
      return true
    }
    else {
      return false
    }
  }


  viewImages(data) {
    this.images = data;
    $('#modalImage').modal('show')
  }

  getComments(id, t) {
    this.tid = t;
    this.ticketId = id;
    this.comments = [];
    this.authService.getComments({ ticketId: id }).subscribe(response => {
      if (response.success == true) {
        this.comments = response.data.comments;
      }
      else {
        this.toasterService.pop('warning', '', response.message);
      }
    })
  }

  addComment() {
    this.authService.addComment({ ticketId: this.ticketId, comment: this.mycomment }).subscribe(response => {
      if (response.success == true) {
        this.toasterService.pop('success', '', response.message);
        this.getComments(this.ticketId, this.tid);
        this.mycomment = '';
        this.getTickets(this.currentPage)

      }
      else {
        this.toasterService.pop('warning', '', response.message);
      }
    })
  }

  deleteTicket(id, val) {
    this.nfs.showConfirmation(
      'Alert!',
      "Are You sure want to delete ",
      'warning',
      'Yes',
    ).then((result) => {
      if (result.value) {
        this.authService.deleteTicket({ ticketId: id }).subscribe(response => {
          if (response.success == true) {
            this.toasterService.pop('success', '', response.message);
            this.setPage(1);
          }
          else {
            this.toasterService.pop('warning', '', response.message);
          }
        })
      }
    })
  }

  updateTicket(id, status) {
    var ticketData = {}
    if (status == 'Assigned') {
      ticketData = {
        "title": this.title,
        "deadline": this.deadLine.formatted,
        "taskType": "General",
        "user": this.currentUser,
        "selectedUser": this.selectedUser,
        "selectedTeamName": [],
        "priority": this.priority,
        "project": "PikMyBox",
        "description": this.desc
      }
    }
    this.authService.updateTicket({ ticketId: id, status: status, ticketData: ticketData }).subscribe(response => {
      if (response.success == true) {
        this.toasterService.pop('success', '', response.message);
        this.setPage(1)
      }
      else {
        this.toasterService.pop('warning', '', response.message);
      }
    })
  }

  getTaskDetails(id, ticketId) {
    this.selectedTicketId = ticketId
    this.authService.getTaskDetails({ ticketId: id }).subscribe(data => {
      if (data.success) {
        this.taskDetails = data.data
      }
      else {
        this.taskDetails = null
      }
    })
  }

  assignTicketBtnClick(ticket) {
    this.selectedTicket = ticket
    this.selectedTicketId = ticket.ticketId;
    this.title = ticket.title;
    this.desc = ticket.desc
  }


  getticketdetails(id) {
    this.description = null;
    this.authService.getticketdetails({ ticketId: id }).subscribe(response => {
      if (response.success == true) {
        this.modal = true;
        this.description = response.data;
        console.log(this.description);
      }
      else {
        this.toasterService.pop('warning', '', response.message);
      }
    })

  }


  openModal() {
    document.getElementById('myModal').style.display = "block";
  }

  closeModal() {
    document.getElementById('myModal').style.display = "none";
  }


  plusSlides(n) {
    this.showSlides(this.slideIndex += n);
  }

  currentSlide(n) {
    this.showSlides(this.slideIndex = n);
  }

  showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides") as HTMLCollectionOf<HTMLElement>;

    if (n > slides.length) { this.slideIndex = 1 }
    if (n < 1) { this.slideIndex = slides.length }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    slides[this.slideIndex - 1].style.display = "block";
  }

  checkEvent(e, i, id) {
    var status = '';
    if (e.target.checked) {

      status = "Approved"
      $('#check' + i).text("Approved");
      this.updateTicket(id, status)
    }
    else {
      status = "Pending"
      $('#check' + i).text("Pending");
      this.updateTicket(id, status)
    }
  }

}

